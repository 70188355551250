import reduce from "lodash/reduce";
import { IpApiInterface, UserDataRequest } from "./types";
import Cookies from "js-cookie";
import {
  prodCaptureUrl,
  prospectIdCookieName,
  stageCaptureUrl,
  utmParams,
} from "./constants";

export const convertToQueryParams = (obj: Record<string, any>): string => {
  const validEntries = Object.entries(obj).filter(([key, value]) => {
    return (
      value !== null && value !== undefined && value !== false && value !== ""
    );
  });
  const queryParams = validEntries
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return queryParams;
};

export const convertQueryToObject = (query?: string): Record<string, any> => {
  const queries = query?.split("&");

  return reduce(
    queries,
    (obj: Record<string, any>, queryObj: string) => {
      const [key, value] = queryObj.split("=");
      const decodedValue = decodeURIComponent(value);

      if (
        decodedValue !== "null" &&
        decodedValue !== "undefined" &&
        decodedValue !== ""
      ) {
        obj[key] = decodedValue;
      }

      return obj;
    },
    {}
  );
};

export const getCookieValue = (
  cookieName: string,
  queryParams?: Record<string, any>
): string | undefined => {
  if (queryParams && queryParams.hasOwnProperty(cookieName)) {
    return queryParams[cookieName];
  }

  return Cookies.get(cookieName);
};

export const getProspectId = () => {
  const lsqProspectId = Cookies.get(`ORG23302`);
  const crmProspectId = Cookies.get(prospectIdCookieName);
  if (lsqProspectId && lsqProspectId !== crmProspectId) {
    Cookies.set(prospectIdCookieName, lsqProspectId, {
      expires: getCookieExpireDate(),
      domain: getCookieDomain(),
    });
  }
  return lsqProspectId ?? crmProspectId;
};

export const getRecordObjects = (
  data: { cookieName: string; apiName: string }[],
  queryParams?: Record<string, any>
): Record<string, any> => {
  return reduce(
    data,
    (result: Record<string, any>, item) => {
      const value = getCookieValue(item.cookieName, queryParams);
      if (value) {
        result[item.apiName] = value;
      }
      return result;
    },
    {}
  );
};
export const getUTMRecordObjects = (): Record<string, any> => {
  const urlUtms = convertQueryToObject(document.URL.split("?")[1] ?? "");
  return getRecordObjects(utmParams, urlUtms);
};

export const getCookieExpireDate = (): Date => {
  const now = new Date();
  return new Date(now.setFullYear(now.getFullYear() + 1));
};

export const getCookieDomain = (): string | undefined => {
  // For skill lync, we don't set domain for backward compatibility reasons
  if (window.location.hostname.includes("skill-lync.com")) {
    return undefined;
  }

  // For all other domains, we set the domain to the root domain
  const domain = window.location.hostname;
  const parts = domain.split(".");

  if (parts.length < 2) return domain;

  return "." + parts.slice(-2).join(".");
};

export const setIpCookies = (response: IpApiInterface) => {
  (Object.keys(response) as Array<keyof IpApiInterface>).forEach(
    (property: keyof IpApiInterface) => {
      Cookies.set(
        `lead_ipapi_${String(property)}`,
        response[property].toString(),
        {
          expires: getCookieExpireDate(),
          domain: getCookieDomain(),
        }
      );
    }
  );
};

export const apiCall = (params?: any): Promise<Response> => {
  const { API_URL } = process.env;
  return fetch(`${API_URL}`, params).then((res) => res.json());
};

export const captureEvent = (event: UserDataRequest) => {
  return apiCall({
    method: "POST",
    keepalive: true,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  });
};
