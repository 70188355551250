import { ApiParams, UserDataRequest } from "./types";

export const stageCaptureUrl = "https://crm-stage.skill-lync.com/capture";
export const prodCaptureUrl = "https://crm-prod.skill-lync.com/capture";

export const currentIPCookieName = "x-skill-lync-ip";
export const cookieIPCookieName = "lead_ip";

export const pageClose = "hidden";

export const leadParams: {
  cookieName: string;
  apiName: keyof UserDataRequest;
}[] = [
  { cookieName: "lead_name", apiName: "firstName" },
  { cookieName: "lead_email", apiName: "emailAddress" },
  { cookieName: "lead_phone", apiName: "phone" },
];

export const utmParams: ApiParams[] = [
  { cookieName: "utm_source", apiName: "source" },
  { cookieName: "utm_medium", apiName: "sourceMedium" },
  { cookieName: "utm_content", apiName: "sourceContent" },
  { cookieName: "utm_set", apiName: "sourceSet" },
  { cookieName: "utm_campaign", apiName: "sourceCampaign" },
  { cookieName: "utm_network", apiName: "utmNetwork" },
  { cookieName: "utm_device", apiName: "utmDevice" },
  { cookieName: "utm_keyword", apiName: "utmKeyword" },
  { cookieName: "gclid", apiName: "googleClickId" },
  { cookieName: "fbclid", apiName: "facebookClickId" },
];

export const prospectIdCookieName = "lead_prospectId";

export const organizationalIdCookieName = "ORG_ID";

export const ipCookieNames: ApiParams[] = [
  { cookieName: prospectIdCookieName, apiName: "prospectId" },
  { cookieName: "lead_ipapi_ip", apiName: "ipAddress" },
  { cookieName: "lead_ipapi_version", apiName: "ipAddresVersion" },
  { cookieName: "lead_ipapi_city", apiName: "city" },
  { cookieName: "lead_ipapi_region", apiName: "region" },
  { cookieName: "lead_ipapi_region_code", apiName: "regionCode" },
  { cookieName: "lead_ipapi_country", apiName: "country" },
  { cookieName: "lead_ipapi_country_name", apiName: "countryName" },
  { cookieName: "lead_ipapi_country_code", apiName: "countryCode" },
  { cookieName: "lead_ipapi_country_code_iso3", apiName: "coutryCodeISO3" },
  { cookieName: "lead_ipapi_in_eu", apiName: "countryInEU" },
  { cookieName: "lead_ipapi_postal", apiName: "postal" },
  { cookieName: "lead_ipapi_latitude", apiName: "latitude" },
  { cookieName: "lead_ipapi_longitude", apiName: "longitude" },
  { cookieName: "lead_ipapi_timezone", apiName: "timezone" },
  { cookieName: "lead_ipapi_utc_offset", apiName: "utcOffset" },
  {
    cookieName: "lead_ipapi_country_calling_code",
    apiName: "countryCallingCode",
  },
  { cookieName: "lead_ipapi_currency", apiName: "currency" },
  { cookieName: "lead_ipapi_languages", apiName: "preferredLanguages" },
  { cookieName: "lead_ipapi_asn", apiName: "asn" },
  { cookieName: "lead_ipapi_org", apiName: "organization" },
  { cookieName: "lead_ipapi_network", apiName: "network" },
  { cookieName: "lead_ipapi_phone", apiName: "phone" },
  { cookieName: "lead_ipapi_name", apiName: "firstName" },
  { cookieName: "lead_ipapi_email", apiName: "emailAddress" },
];
